.linkBlockLink {
  color: var(--text-color);
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 1em;
}
.linkBlockLink:hover {
  color: var(--hover-color);
}
.linkBlockButton {
  padding: 0.5em 1em;
  border-radius: 10px;
  background-color: var(--background-color);
  border: 2px solid var(--background-color);
}
.linkBlockButton:hover {
  background-color: var(--text-color);
  border-color: var(--hover-color);
}
.linkBlockButton i {
  margin: 0 0.5em 0 0;
}
.linkBlockButton .noName {
  margin: 0 0.5em;
}

#footer {
  background-color: #374D2C;
  border: unset;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
  text-align: center;
  height: auto;
}
#footer hr {
  width: 70%;
  border-color: #fff;
  color: #fff;
  border-style: solid;
}
.footerTop {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
}
.footerTop > div {
  margin: 0.5em 0;
}
.logoWhiteDiv {
  display: flex;
  justify-content: center;
}
.logoWhite {
  width: 25em;
  height: -moz-fit-content;
  height: fit-content;
}
.footerBottom {
  width: 100%;
  display: flex;
  justify-content: center;
}
.footerBottom .footerList {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footerBottom .footerList .item {
  padding: unset;
  margin: 0.4em 0;
}
.footerList .item {
  color: #fff;
  line-height: 18px;
}
.footerList .item i {
  margin: 0 0.3em 0 0;
  font-size: 14pt;
}
.footerList .item i.ri-copyright-line {
  margin-right: 0.1em;
}
@media only screen and (min-width: 600px) {
  #footer {
    text-align: left;
  }
  .footerTop {
    flex-direction: row;
    flex-wrap: unset;
    align-content: unset;
    width: 70%;
  }
  .footerList {
    margin: unset;
  }
  .footerBottom .footerList {
    flex-direction: row;
  }
  .footerBottom .footerList .item {
    margin: 0 0.5em;
  }
  .logoWhite {
    width: 30em;
  }
}
.footerName {
  font-weight: bold;
}
